import axios from 'axios'

export const getUser = async (data) => {
  const res = await axios.post('/api/tssw/getUser', data)
  return res.data
}

export const getUsers = async (env) => {
  const res = await axios.post('/api/tssw/getUsers', { env })
  return res.data
}

export const createUser = async (data) => {
  const res = await axios.post('/api/tssw/createUser', data)
  return res.data
}

export const updateUser = async (data) => {
  const res = await axios.post('/api/tssw/updateUser', data)
  return res.data
}

export const deleteUser = async (data) => {
  const res = await axios.post('/api/tssw/deleteUser', data)
  return res.data
}

export const deleteUsers = async ({ userIds }) => {
  const res = await axios.post('/api/tssw/deleteUsers', { userIds })
  return res.data
}
