<template>
  <AntFormItem :name="name" :label="label">
    <AntTooltip
      v-if="toolTip"
      :trigger="toolTipTrigger"
      placement="right"
    >
      <template #title>
        {{ toolTip }}
      </template>
      <component
        :is="`ant-${element}`"
        :type="inputType"
        v-bind="{
          ...$attrs,
          ...bindValue(name),
        }"
        @change="(e) => onChange(e, name)"
      />
    </AntTooltip>

    <component
      v-else
      :is="`ant-${element}`"
      :type="inputType"
      v-bind="{
        ...$attrs,
        ...bindValue(name),
      }"
      @change="(e) => onChange(e, name)"
    />
  </AntFormItem>
</template>

<script>
import { reactive } from 'vue'

import {
  Form as AntForm,
  Tooltip as AntTooltip,
  Input as AntInput,
  InputNumber as AntInputNumber,
  Select as AntSelect,
  Checkbox as AntCheckbox,
  Radio as AntRadio,
  Switch as AntSwitch,
  Button as AntButton,
} from 'ant-design-vue'

export default {
  name: 'FormItem',
  props: {
    formState: {
      type: Object,
      required: true,
    },
    element: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    label: String,
    inputType: String,
    toolTip: String,
    toolTipTrigger: String,
  },
  components: {
    AntTooltip,
    AntFormItem: AntForm.Item,
    AntInput,
    AntInputPassword: AntInput.Password,
    AntTextarea: AntInput.TextArea,
    AntInputNumber,
    AntSelect,
    AntCheckbox,
    AntCheckboxGroup: AntCheckbox.Group,
    AntRadio: AntRadio.Group,
    AntSwitch,
    AntButton,
  },
  inheritAttrs: false,
  setup(props) {
    const formState = reactive(props.formState)

    const bindValue = key => ({
      ...(['input', 'input-number', 'input-password', 'textarea', 'select', 'radio', 'checkbox-group'].includes(props.element) && { value: formState[key] }),
      ...(['switch', 'checkbox'].includes(props.element) && { checked: formState[key] }),
    })

    const onChange = (e, key) => {
      if (['input', 'textarea', 'radio'].includes(props.element)) formState[key] = e.target.value.trim()
      if (['input-password'].includes(props.element)) formState[key] = e.target.value
      if (['checkbox'].includes(props.element)) formState[key] = e.target.checked
      if (['input-number', 'select', 'switch', 'checkbox-group'].includes(props.element)) formState[key] = e
    }

    return {
      bindValue,
      onChange,
    }
  },
}
</script>

<style scoped>
.ant-input-number {
  width: 100%;
}
</style>
