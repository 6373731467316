<template>
  <AntForm
    ref="formRef"
    :model="formState"
    :rules="formRules"
    :layout="layout"
    :labelCol="labelCol"
    :wrapperCol="wrapperCol"
    labelAlign="left"
  >
    <template
      v-for="({
        id,
        rules, // eslint-disable-line vue/no-unused-vars
        ...args
      }) in formFields"
      :key="id"
    >
      <FormItem
        :name="id"
        :formState="formState"
        v-bind="args"
      />
    </template>

    <AntFormItem
      justify="center"
      style="text-align: center"
    >
      <AntButton
        type="primary"
        html-type="submit"
        @click="onFormSubmit"
        :loading="buttonLoading"
      >
        {{ buttonText }}
      </AntButton>
    </AntFormItem>

    <!-- <p><b>formState:</b> {{ formState }}</p> -->
    <!-- <p><b>formFields:</b> {{ formFields }}</p> -->
  </AntForm>
</template>

<script>
import {
  ref,
  reactive,
  watch,
  computed,
  toRaw,
} from 'vue'

import {
  Form as AntForm,
  Button as AntButton,
} from 'ant-design-vue'

import FormItem from '../FormItem'

export default {
  name: 'FormTemplate',
  props: {
    defaultData: {
      type: Object,
      default() {
        return {}
      },
    },
    formFields: {
      type: Array,
      required: true,
    },
    buttonText: {
      type: String,
      default: 'Submit',
    },
    buttonLoading: {
      type: Boolean,
      default: false,
    },
    labelCol: {
      type: Object,
      default: () => ({ span: 6 }),
    },
    wrapperCol: {
      type: Object,
      default: () => ({ span: 18 }),
    },
    formState: Object,
    layout: String,
    onSubmit: Function,
  },
  components: {
    AntForm,
    AntFormItem: AntForm.Item,
    AntButton,
    FormItem,
  },
  inheritAttrs: false,
  setup(props) {
    const formRef = ref()

    // props.formFields 作為初始值使用 reactive 方法創建响應式物件 formState
    // props.formFields 發生變化時，這個初始值不會更新，需使用 watch 監聽 props.formFields 的變化
    const formState = props.formState ? reactive(props.formState) : reactive(props.formFields.reduce((acc, { id, defaultValue }) => {
      acc[id] = props.defaultData[id] ?? defaultValue
      return acc
    }, {}))

    // props.formFields 發生變化時，computed 會重新計算
    const formRules = computed(() => props.formFields.reduce((acc, { id, rules }) => {
      if (rules) acc[id] = rules
      return acc
    }, {}))

    // 因 props.formFields 是普通物件為非 reactive，需 watch 變化
    // 若 props.formFields 在 Parent 中為 reactive 物件，Child 則會相應更新而不需 watch
    if (!props.formState) {
      watch(() => props.formFields, (newFormFields) => {
        formRef.value.clearValidate()
        Object.values(newFormFields).forEach(({ id, defaultValue }) => { formState[id] = defaultValue })
        Object.keys(formState).forEach((key) => { if (newFormFields.findIndex(({ id }) => id === key) < 0) delete formState[key] })
      }, { deep: true })
    }

    const onFormSubmit = () => {
      formRef.value.validate()
        .then(() => {
          props.onSubmit({ ...toRaw(formState) })
        })
        .catch((error) => {
          console.error('error', error, 'formState', toRaw(formState))
        })
    }

    return {
      formRef,
      formState, // eslint-disable-line vue/no-dupe-keys
      formRules,
      onFormSubmit,
    }
  },
}
</script>
