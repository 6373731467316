<template>
  <AntRow>
    <AntCol
      :sm="{ span: 24, offset: 0 }"
      :md="{ span: 20, offset: 2 }"
      :lg="{ span: 16, offset: 4 }"
    >
      <AntRow class="ant-form-item">
        <AntCol :span="6">
          Current Balance:
        </AntCol>
        <AntCol :span="18">
          $ {{ loginUserData.balance }}
        </AntCol>
      </AntRow>

      <FormTemplate
        :defaultData="loginUserData"
        :formFields="formFields"
        :onSubmit="onSubmit"
      />

      <AntAlert
        v-if="!!alertState.message"
        :type="alertState.type"
        :message="alertState.message"
        showIcon
      />
    </AntCol>
  </AntRow>
</template>

<script>
import { reactive } from 'vue'
import { useRouter } from 'vue-router'

import {
  Row as AntRow,
  Col as AntCol,
  Alert as AntAlert,
} from 'ant-design-vue'

import { updateUser } from '@/api/tssw'
import { USER_SETTINGS_FORM_FIELDS } from '@/constants/tssw'

import FormTemplate from '@/components/AgentManagement/FormTemplate'

export default {
  name: 'UserSettings',
  components: {
    AntRow,
    AntCol,
    AntAlert,
    FormTemplate,
  },
  props: {
    awcEnv: String,
    loginUserData: Object,
    requestResponseData: Object,
  },
  emits: ['changeLoginUserData'],
  setup(props, { emit }) {
    const router = useRouter()
    if (!props.loginUserData.userId) router.push('/tssw/login')

    const alertState = reactive({})

    const onSubmit = async (formState) => {
      const { data: updateUserData, message } = await updateUser({
        userId: props.loginUserData.userId,
        env: props.awcEnv,
        ...formState,
      })

      if (updateUserData) {
        alertState.type = 'success'
        alertState.message = 'Updated successfully'
        emit('changeLoginUserData', updateUserData)
      } else if (message) {
        alertState.type = 'error'
        alertState.message = message
      }
    }

    return {
      formFields: USER_SETTINGS_FORM_FIELDS,
      alertState,
      onSubmit,
    }
  },
}
</script>
