import {
  GAMETYPE_OPTIONS,
  CURRENCY_OPTIONS,
  LANGUAGE_OPTIONS,
} from './index'

export const AGENT_CONFIG = {
  UAT: {
    cert: 'rGjZikeTOMVR9g11RMY',
    agentId: 'tsswuat',
  },
  STG: {
    cert: '18YDtxChFc24nf7763C',
    agentId: 'tsswstg',
  },
  AWCA: {
    cert: '7hnNHX8EhOVzSIcGRvA',
    agentId: 'qaautotest',
  },
  AWCA_RP: {
    cert: '14ctYcSqBLsnZhmgNqI',
    agentId: 'qaautotest2',
  },
  AWS03: {
    cert: 'exKReVsYPOVe3mxNYSV',
    agentId: 'qaautotest1',
  },
  AWS03_RP: {
    cert: 'bv8vdy4qhaHKCuBUbAQ',
    agentId: 'testtssw',
  },
  AWS06: {
    cert: '5j4vSb99DsDfmoqqqDm',
    agentId: 'qaautotest',
  },
}

const DEFAULT_BET_LIMIT = JSON.stringify({
  SEXYBCRT: {
    LIVE: {
      limitId: [280301, 280302, 280303],
    },
  },
  VENUS: {
    LIVE: {
      limitId: [280301, 280302, 280303],
    },
  },
  SV388: {
    LIVE: {
      minbet: 1,
      maxbet: 1000,
      mindraw: 1,
      maxdraw: 100,
      matchlimit: 1000,
    },
  },
  HORSEBOOK: {
    LIVE: {
      minbet: 10,
      maxbet: 3000,
      maxBetSumPerHorse: 10000,
      minorMinbet: 10,
      minorMaxbet: 1000,
      minorMaxBetSumPerHorse: 10000,
    },
  },
  BG: {
    LIVE: {
      limitId: ['H4', 'J4', 'H7'],
    },
  },
  AELIVECOMM: {
    LIVE: {
      limitId: [280301, 280302, 280303],
    },
  },
})

const USERID_FIELD = [
  {
    id: 'userId',
    label: 'User ID',
    defaultValue: undefined,
    element: 'input',
    inputType: 'text',
    maxlength: 21,
    rules: [
      {
        pattern: /^[A-Za-z0-9]+$/,
        message: 'Please enter the correct format',
        trigger: 'blur',
      },
    ],
  },
]

const BETLIMIT_FIELD = [
  {
    id: 'betLimit',
    label: 'Bet Limit',
    defaultValue: undefined,
    element: 'textarea',
    inputType: 'text',
    autoSize: { minRows: 3, maxRows: 8 },
    rules: [
      {
        validator: (rule, value) => {
          if (value) {
            try {
              const parseValue = JSON.parse(value)
              return Promise.resolve(parseValue)
            } catch (error) {
              return Promise.reject(new Error('Please enter the correct format'))
            }
          }
          return Promise.resolve()
        },
      },
    ],
  },
]

const PLATFORM_GAMETYPE_FIELDS = [
  {
    id: 'platform',
    label: 'Platform',
    defaultValue: undefined,
    placeholder: 'SEXYBCRT',
    element: 'select',
    // options: PLATFORM_OPTIONS,
    showSearch: true,
  },
  {
    id: 'gameType',
    label: 'Game Type',
    defaultValue: undefined,
    placeholder: 'LIVE',
    element: 'select',
    options: GAMETYPE_OPTIONS,
    showSearch: true,
  },
]

const CURRENCY_FIELD = [
  {
    id: 'currency',
    label: 'Currency',
    defaultValue: 'CNY',
    element: 'select',
    options: CURRENCY_OPTIONS,
    showSearch: true,
  },
]

const LANGUAGE_FIELD = [
  {
    id: 'language',
    label: 'Language',
    defaultValue: undefined,
    element: 'select',
    options: LANGUAGE_OPTIONS,
  },
]

const ISMOBILELOGIN_FIELD = [
  {
    id: 'isMobileLogin',
    label: 'Mobile Login',
    defaultValue: false,
    element: 'checkbox',
    inputType: 'checkbox',
  },
]

const EXTERNALURL_ISMOBILELOGIN_AUTOBETMODE_FIELDS = [
  {
    id: 'externalURL',
    label: 'External URL',
    defaultValue: undefined,
    element: 'input',
    inputType: 'text',
    placeholder: '',
    rules: [
      {
        type: 'url',
        message: 'Please enter the correct URL format',
        trigger: 'blur',
      },
    ],
  },
  ...ISMOBILELOGIN_FIELD,
  {
    id: 'autoBetMode',
    label: 'Auto Bet Mode',
    defaultValue: false,
    element: 'checkbox',
    inputType: 'checkbox',
  },
]

export const CREATE_MEMBER_FORM_FIELDS = [
  {
    id: 'storageLocations',
    label: 'Create Member in',
    defaultValue: ['tssw', 'awc'],
    element: 'checkbox-group',
    options: [
      { label: 'TSSW', value: 'tssw' },
      { label: 'AWC', value: 'awc' },
    ],
    rules: [
      {
        required: true,
        message: 'Required',
      },
    ],
  },
  {
    ...USERID_FIELD[0],
    rules: [
      ...USERID_FIELD[0].rules,
      {
        required: true,
        message: 'Required',
      },
    ],
  },
  ...BETLIMIT_FIELD.map(({ rules, ...f }) => ({
    ...f,
    defaultValue: DEFAULT_BET_LIMIT,
    rules: [
      ...rules,
      // {
      //   required: true,
      //   message: 'Required',
      // },
    ],
  })),
  {
    ...CURRENCY_FIELD[0],
    rules: [
      {
        required: true,
        message: 'Required',
      },
    ],
  },
  {
    ...LANGUAGE_FIELD[0],
    defaultValue: 'en',
  },
  {
    id: 'userName',
    label: 'User Name',
    defaultValue: undefined,
    element: 'input',
    maxlength: 20,
    rules: [
      {
        pattern: /^[A-Za-z0-9]+$/,
        message: 'Please enter the correct format',
        trigger: 'blur',
      },
    ],
  },
]

export const LOGIN_FORM_FIELDS = [
  {
    ...USERID_FIELD[0],
    rules: [
      ...USERID_FIELD[0].rules,
      {
        required: true,
        message: 'Required',
      },
    ],
  },
  {
    ...BETLIMIT_FIELD[0],
    placeholder: DEFAULT_BET_LIMIT,
  },
  ...PLATFORM_GAMETYPE_FIELDS,
  {
    id: 'gameForbidden',
    label: 'Game Forbidden',
    defaultValue: undefined,
    element: 'input',
    placeholder: '{"JDBFISH":{"FH":["ALL"]}}',
    rules: [
      {
        validator: (rule, value) => {
          if (value) {
            try {
              const parseValue = JSON.parse(value)
              return Promise.resolve(parseValue)
            } catch (error) {
              return Promise.reject(new Error('Please enter the correct format'))
            }
          }
          return Promise.resolve()
        },
      },
    ],
  },
  ...LANGUAGE_FIELD,
  ...EXTERNALURL_ISMOBILELOGIN_AUTOBETMODE_FIELDS,
]

export const DO_LOGIN_FORM_FIELDS = [
  {
    ...USERID_FIELD[0],
    rules: [
      ...USERID_FIELD[0].rules,
      {
        required: true,
        message: 'Required',
      },
    ],
  },
  {
    ...BETLIMIT_FIELD[0],
    placeholder: '{"SEXYBCRT":{"LIVE":{"limitId":[280301,280302,280303]}}}',
  },
  ...PLATFORM_GAMETYPE_FIELDS.map(f => ({
    ...f,
    rules: [
      {
        required: true,
        message: 'Required',
      },
    ],
  })),
  {
    id: 'gameCode',
    label: 'Game Code',
    defaultValue: undefined,
    element: 'input',
    inputType: 'text',
    placeholder: 'MX-LIVE-001',
    rules: [
      {
        required: true,
        message: 'Required',
      },
    ],
  },
  {
    id: 'hall',
    label: 'Hall',
    defaultValue: undefined,
    element: 'input',
    placeholder: 'SEXY',
  },
  ...LANGUAGE_FIELD,
  ...EXTERNALURL_ISMOBILELOGIN_AUTOBETMODE_FIELDS,
]

export const UPDATE_BET_LIMIT_FORM_FIELDS = [
  {
    ...BETLIMIT_FIELD[0],
    label: 'Update Bet Limit',
  },
]

export const USER_SETTINGS_FORM_FIELDS = [
  {
    id: 'balance',
    label: 'Update Balance',
    defaultValue: undefined,
    element: 'input-number',
    inputType: 'number',
    placeholder: '',
    min: -9999999999999.999,
    max: 9999999999999.999,
    step: 0.001,
  },
  {
    id: 'disableBetResponse',
    label: 'Disable Bet Response',
    defaultValue: false,
    element: 'switch',
    toolTip: 'Switch ON ➔ no response to time out',
  },
  {
    id: 'nullBetResponse',
    label: 'Null Bet Response',
    defaultValue: false,
    element: 'switch',
    toolTip: 'Switch ON ➔ respond to bet with null',
  },
  {
    id: 'failedBetStatus',
    label: 'Failed Bet Status',
    defaultValue: false,
    element: 'switch',
    toolTip: 'Switch ON ➔ respond to bet with HTTP code = 400',
  },
  {
    id: 'failedSettleResponse',
    label: 'Failed Settle Response',
    defaultValue: false,
    element: 'switch',
    toolTip: 'Switch ON ➔ respond to settle with status = 9999',
  },
]
